<template>
  <div class="check_course_card_wrapper" :key="courseInfo.chargeCourseId">
    <img class="card_image" :src="courseInfo.coverUrl" alt="" />
    <p class="title no_wrap">{{ courseInfo.chargeCourseName }}</p>
    <p class="desc no_wrap">
      {{ courseInfo.chargeCourseBeginDate }} ~ {{ courseInfo.chargeCourseEndDate }}
    </p>
    <p class="desc no_wrap">{{ courseInfo.teacherName }}</p>
    <div class="line"></div>
    <div class="btn_group">
      <button
        class="detail"
        :class="!courseInfo.canChoice || !restCount ? 'disableDetail' : null"
        @click="handleToCourse"
      >
        详情
      </button>
      <button :class="courseInfo.canChoice || !restCount ? 'disable' : 'primary'" @click="goCheck">
        {{ courseInfo.canChoice ? "已选" : "立即选择" }}
      </button>
    </div>
  </div>
</template>
<script>
import { pagePush } from "@/utils/wyUtil";

export default {
  name: "checkItem",
  props: {
    courseInfo: {
      type: Object,
      default: () => ({})
    },
    goCheck: {
      type: Function,
      default: () => {}
    },
    restCount: {
      type: Number
    }
  },
  data() {
    return {};
  },
  methods: {
    handleToCourse() {
      if (!this.courseInfo.canChoice || !this.restCount) {
        // this.$message.error('请先选择课程哦!');
        pagePush(`/courseDetail/${this.courseInfo.chargeCourseId}?mode=0`);
        return false;
      }
      const str = !this.courseInfo.canChoice ? "?group=n" : "";
      pagePush(`/courseDetail/${this.courseInfo.chargeCourseId}${str}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.check_course_card_wrapper {
  width: 292px;
  background: #ffffff;
  box-shadow: 0 2px 16px 0 rgba(202, 205, 214, 0.47);
  border-radius: 6px;
  margin-bottom: 20px;
  margin-right: 25px;
  overflow: hidden;
  cursor: pointer;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .card_image {
    width: 292px;
    height: 171px;
    background: #eee;
  }
  .no_wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .title {
    width: 291px;
    height: 27px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    padding: 5px 10px 0;
  }
  .desc {
    width: 100%;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    color: #a0a2ab;
    line-height: 24px;
    padding: 0 10px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: rgba(114, 119, 126, 0.1);
  }
  .btn_group {
    height: 35px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      border: none;
      outline: none;
      background: #fff;
      font-size: 12px;
      cursor: pointer;
    }
    .detail {
      color: #72777e;
    }
    .primary {
      color: #3377ff;
    }
    .disable {
      color: #999;
      cursor: auto;
    }
    .disableDetail {
      color: #bbb;
      cursor: not-allowed;
    }
  }
}
</style>
