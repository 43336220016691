<template>
  <div class="study_course_check_list">
    <back-btn class="back_btn"></back-btn>
    <el-select v-model="activeGoodsId" placeholder="请选择" class="selector" @change="handleChange">
      <el-option v-for="item in tagsList" :key="item.id" :label="item.name" :value="item.id">
      </el-option>
    </el-select>
    <span class="count_tip_text">剩余 {{ activeRestCount }} 科未选课</span>
    <div class="cards_container">
      <check-item
        v-for="course in scCourseList"
        :key="course.chargeCourseId"
        :course-info="course"
        :rest-count="activeRestCount"
        :go-check="() => openDialog(course)"
      />
    </div>
  </div>
  <el-dialog title="操作提示" v-model="dialogVisible" :center="true" width="435px">
    <div class="checkModalStyle">
      <p class="common_text text_center font16">即将选择课程：</p>
      <p class="common_text text_center orange font20">【{{ activeData.chargeCourseName }}】</p>
      <p class="common_text">
        上课时间：{{ activeData.chargeCourseBeginDate }} ~
        {{ activeData.chargeCourseEndDate }}
      </p>
      <p class="common_text">上课老师：{{ activeData.teacherName }}</p>
      <el-input
        class="confirm_input"
        placeholder="请输入“确认选课”"
        v-model="confirmStr"
        :destroy-on-close="true"
        @input="handleInput"
      ></el-input>
      <p v-show="showTip" class="common_text font16 orange">请输入“确认选课”</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { connectState } from "@/utils/wyUtil";
import BackBtn from "@/components/BackBtn.vue";
import CheckItem from "./components/CheckItem.vue";

export default {
  components: { CheckItem, BackBtn },
  data() {
    return {
      dialogVisible: false,
      activeData: {},
      confirmStr: "",
      showTip: true,
      tagsList: [],
      activeGoodsId: "",
      activeRestCount: 0
    };
  },
  created() {
    this.getGoodsList();
    // this.getStudyCardCount();
  },
  computed: {
    ...connectState("course", ["scGoodsList", "scCourseList"])
  },
  watch: {
    scGoodsList(val) {
      if (Array.isArray(val) && val.length) {
        const hasTarget = this.activeGoodsId
          ? val.find(item => item.itemId == this.activeGoodsId && item)
          : false;
        this.activeGoodsId = hasTarget ? this.activeGoodsId : val[0].itemId || "";
        this.activeRestCount = hasTarget ? hasTarget.num : val[0].num || 0;
      } else {
        this.activeGoodsId = "";
        this.activeRestCount = 0;
      }
      this.tagsList = Array.isArray(val)
        ? val.map(item => ({ name: item.itemName, id: item.itemId, num: item.num }))
        : [];
    }
  },
  methods: {
    getGoodsList() {
      this.$store.dispatch("course/getScGoods", { goodsId: "" });
    },
    getStudyCardCount() {
      // this.$store.dispatch('course/getRestClassCourse');
    },
    getStudyCourses(id) {
      this.$store.dispatch("course/fetchStudyCardCourses", { itemId: id });
    },
    openDialog(data) {
      if (data.canChoice || !this.activeRestCount) {
        return false;
      }
      this.dialogVisible = true;
      this.activeData = data || {};
      this.confirmStr = "";
      this.showTip = true;
    },
    handleInput(val) {
      const str = val.trim();
      this.confirmStr = str;
      this.showTip = this.checkInput(str);
    },
    checkInput(val) {
      const str = val ? val.trim() : "";
      const reg = /^确认选课$/gi;
      return !reg.test(str);
    },
    handleConfirm() {
      if (this.checkInput(this.confirmStr)) {
        this.$message({ message: "请输入“确认选课”", type: "error" });
        return false;
      }
      this.$store.dispatch("course/checkCourse", {
        chargeCourseId: this.activeData.chargeCourseId,
        goodsId: this.activeGoodsId,
        callback: () => {
          this.$message({ message: "选课成功", type: "success" });
          this.dialogVisible = false;
          this.activeData = {};
        }
      });
    },
    handleChange(val) {
      this.activeGoodsId = val || val === 0 ? val : (this.tagsList[0] || {}).id;
      this.activeRestCount = (this.tagsList.find(item => item.id === val && item) || {}).num || 0;
      this.getStudyCourses(this.activeGoodsId);
    }
  }
};
</script>
<style lang="scss" scoped>
.study_course_check_list {
  width: 965px;
  height: auto;
  min-height: 500px;
  border-radius: 6px;

  .selector,
  .back_btn {
    margin-bottom: 20px;
  }
  .count_tip_text {
    color: #3377ff;
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
  }
  .cards_container {
    padding: 15px;
    width: 965px;
    height: auto;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
}
.checkModalStyle {
  .common_text {
    font-size: 17px;
    font-weight: 400;
    color: #4e596f;
    line-height: 26px;
  }
  .text_center {
    width: 100%;
    text-align: center;
  }
  .font16 {
    font-size: 15px;
  }
  .font20 {
    font-size: 19px;
  }
  .orange {
    color: #ffaa1e;
  }
  .confirm_input {
    margin: 5px 0;
  }
}
</style>
<style lang="scss">
.study_course_check_list {
  .el-input__inner {
    border: none;
    padding: 0 30px;
  }
}
</style>
